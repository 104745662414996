/*                                   ROOT SHIT                                */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Roboto&display=swap");

* {
  margin: 0;
  padding: 0;
}

:root {
  --header-background: rgba(110, 115, 120, 0.425);
  --home-main-text: rgb(67, 56, 56);
  --home-sub-text: rgb(93, 91, 91);
  --default-text: white;
  --default-background: rgb(35, 36, 37);
}

html {
  font-family: "Inter", sans-serif;
  color: var(--default-text);
  background-color: var(--default-background);
}

section {
  padding: 36px 6vw;
}

/*                                    HEADER                                  */

header {
  display: flex;
  justify-content: space-between;
  z-index: 5;
  width: max(27vw, 325px);
  font-size: 1.1rem;
  font-weight: 700;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  border-left: 1px black solid;
  border-right: 1px black solid;
  border-bottom: 1px black solid;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  padding: 12px 16px;
  background-color: var(--header-background);
  backdrop-filter: blur(2px);
}

.headerItem {
  cursor: pointer;
}

.headerItem:hover {
  color: rgb(184, 184, 184);
}

.active {
  color: gray;
}

.title {
  font-size: 2rem;
  font-weight: 600;
}

/*                                     HOME                                   */

.home {
  position: relative;
  height: 100vh;
  background-image: url("./assets/parallax/nyc-background.png");
  background-size: cover;
  background-position: center;
  overflow: hidden;
  z-index: -10;
}

.home-container {
  height: 100%;
  width: 100%;
  padding-top: 150px;
  text-align: center;
}

.home-title {
  font-size: max(3.5vw, 3.3rem);
  font-weight: 600;
  color: var(--home-main-text);
}

.home-subtitle {
  font-size: 1.5rem;
  color: var(--home-sub-text);
}

.parallax-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  z-index: 1;
}

.background-bottom {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  background-image: url("./assets/parallax/background-bottom.png");
}

.around-world-trade-closer {
  background-image: url("./assets/parallax/around-world-trade-closer.png");
}

.around-world-trade-farther {
  background-image: url("./assets/parallax/around-world-trade-farther.png");
}

.world-trade-center {
  z-index: 0;
  background-image: url("./assets/parallax/world-trade-center.png");
}

.ferry {
  background-image: url("./assets/parallax/ferry.png");
  z-index: 3;
}

.left-side-behind {
  background-image: url("./assets/parallax/left-side-behind.png");
}

.left-side-overlaps {
  background-image: url("./assets/parallax/left-side-overlaps.png");
}

.right-side {
  background-image: url("./assets/parallax/right-side.png");
}

.blur-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 13vh;
  backdrop-filter: blur(1px); /* Adjust the blur amount as needed */
  z-index: 2;
}
/*                                      ME                                    */

.me .title {
  padding-bottom: 32px;
}

.me-main {
  display: flex;
  gap: 5vw;
  align-items: center;
}

.me-intro {
  line-height: 1.4rem;
}

.img-me {
  height: min(220px, 28vw);
  border-radius: 1000px;
}

/*                                     WORK                                   */

.work {
  display: flex;
  align-items: center;
  width: 80vw;
  max-height: 500px;
  margin-left: 30px;
  padding-bottom: 64px;
}

.work-video-wrapper {
  width: 50%;
  max-width: 1000px;
}

.work-video {
  width: 100%;
}

.work-main {
  margin-left: -30px;
  margin-top: 40vh;
  padding: 20px;
  gap: 12px;
  z-index: 2;
  width: 50%;
  display: flex;
  flex-direction: column;
  background-color: rgba(128, 128, 128, 0.658);
  backdrop-filter: blur(5px);
  box-shadow: 5px 10px 5px rgba(0, 0, 0, 0.322);
}

.works .title {
  padding-bottom: 32px;
}

.work:nth-child(2) {
  padding-bottom: 0;
}

.work-line {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
}

.work-description {
  padding-top: 2px;
}

.project.work:nth-child(2) .project-main {
  margin-top: 10vh;
}

.work:nth-child(2n) {
  flex-direction: row-reverse;
  position: relative;
}
.work:nth-child(2n) .work-main {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 0px;
}

/*                                   PROJECTS                                 */

.projects-wrapper {
  padding-top: 64px;
  display: flex;
  flex-direction: column;
  gap: 64px;
}

.project {
  display: flex;
  align-items: center;
  width: 80vw;
  max-height: 500px;
  margin-left: 30px;
}

.project-video-wrapper {
  width: 50%;
}

.project-video {
  width: 100%;
}

.project-main {
  margin-left: -30px;
  margin-top: 40vh;
  padding: 20px;
  gap: 12px;
  z-index: 2;
  width: 50%;
  display: flex;
  flex-direction: column;
  background-color: rgba(128, 128, 128, 0.658);
  backdrop-filter: blur(5px);
  box-shadow: 5px 10px 5px rgba(0, 0, 0, 0.322);
}

.project-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.project-date {
  font-weight: 500;
  font-size: 1.2rem;
}

.hidden {
  opacity: 0;
  transition: all 1s;
}

.show {
  opacity: 1;
}

.project:nth-child(2n) {
  flex-direction: row-reverse;
  position: relative;
}
.project:nth-child(2n) .project-main {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 0px;
}

/* TODO: ADJUST THESE VALUES TO CHANGE DESCRIPTION HEIGHT FOR PARALLAX */

@media (min-width: 900px) {
  .project:nth-child(1) .project-main {
    margin-top: 80vh;
  }
  .project:nth-child(2) .project-main {
    margin-top: 50vh;
  }
  .project:nth-child(3) .project-main {
    margin-top: 100vh;
  }
  .project:nth-child(4) .project-main {
    margin-top: 70vh;
  }
  .project:nth-child(5) .project-main {
    margin-top: 130vh;
  }
  .project:nth-child(6) .project-main {
    margin-top: 80vh;
  }
}

@media (min-width: 900px) {
  .work:nth-child(2) .work-main {
    margin-top: 35vh;
  }
  .work.work:nth-child(2) .work-main {
    margin-top: 20vh;
  }
  .work:nth-child(3) .work-main {
    margin-top: 60vh;
  }
  .work:nth-child(4) .work-main {
    margin-top: 30vh;
  }
  .work:nth-child(5) .work-main {
    margin-top: 100vh;
  }
}

/*    END TODO    */

.project-title {
  font-weight: 600;
  font-size: 1.3rem;
}

.project-tech-stack {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.project-icon {
  height: 40px;
  cursor: pointer;
}

/*                                   EDUCATION                                */

.education .title {
  padding-bottom: 32px;
}

.education-main {
  display: flex;
  width: 100%;
  gap: 5vw;
}

.education-intro {
  line-height: 1.4rem;
}

.bingLogo {
  height: min(220px, 28vw);
  border-radius: 1000px;
}

.education-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.education-content-top {
  display: flex;
  justify-content: space-between;
}

.education-school {
  font-weight: 600;
  font-size: 1.7rem;
}

.education-date {
  font-weight: 500;
  font-size: 1.6rem;
}

/*                                    CONTACT                                 */

.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 300px;
  gap: 64px;
}

.contact-title {
  animation: blink 0.65s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid;
  font-size: 3.5rem;
  font-weight: 500;
  letter-spacing: 1.4px;
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}

.contact-email {
  font-size: 1.5rem;
  letter-spacing: 1px;
}

.contact-icons {
  display: flex;
  gap: 115px;
}

.linked-in-icon {
  height: 64px;
  width: 75px;
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(73deg)
    brightness(103%) contrast(103%);
}

/*                                    Footer                                 */

footer {
  height: 32px;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 300;
}

/*                                    MOBILE                                 */

@media (max-width: 650px) {
  .me-main {
    flex-direction: column;
  }
  .img-me {
    height: 150px;
  }
}

@media (max-height: 700px) {
  .home-container {
    padding-top: 100px;
  }
}

@media (max-width: 1290px) {
  .work:nth-child(2) {
    padding-bottom: 64px;
  }
}

@media (max-width: 900px) {
  .work:nth-child(2) {
    padding-bottom: 30px;
  }
  .projects-wrapper {
    gap: 164px;
  }
  .project,
  .work {
    flex-direction: column;
  }
  .project-main {
    width: 100%;
  }
  .work-main {
    width: 100%;
    margin-top: 30px;
  }
  .project-video-wrapper,
  .work-video-wrapper {
    margin-right: 30px;
    width: 90%;
  }

  .project:nth-child(1) .project-main {
    margin-top: 100px;
  }
  .project:nth-child(2) .project-main {
    margin-top: 110px;
  }
  .project:nth-child(3) .project-main {
    margin-top: 120px;
  }
  .project:nth-child(4) .project-main {
    margin-top: 140px;
  }
  .project:nth-child(5) .project-main {
    margin-top: 160px;
  }
  .project:nth-child(6) .project-main {
    margin-top: 160px;
  }

  .work:nth-child(4) .work-main {
    margin-top: 70px;
  }

  .project:nth-child(2n),
  .work:nth-child(2n) {
    flex-direction: column;
  }
  .project:nth-child(2n) .project-main,
  .work:nth-child(2n) .work-main {
    position: static;
    margin-right: 30px;
  }
  .project.work .project-main {
    margin-top: 20px;
  }
  .project.work:nth-child(2) .project-main {
    margin-top: 20px;
  }
}
